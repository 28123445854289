import 'core-js/stable'

import RailsUjs from '@rails/ujs'
import * as Turbolinks from 'turbolinks'
import { registerComponent } from 'shared/helper/setup'

Turbolinks.start()
RailsUjs.start()

registerComponent('misc/TrackingVisualizer', () => import('devise/misc/TrackingVisualizer'))
registerComponent('SetPassword', () => import('devise/SetPassword'))
registerComponent('molecule/SetPasswordButton', () => import('devise/molecule/SetPasswordButton'))
registerComponent(
  'AlternativeDivider',
  () => import('components_contributor/atoms/OnboardingFlow/AlternativeDivider'),
)
registerComponent(
  'ToastManager',
  () => import('components_shared/Toast/ToastManager'),
)
registerComponent(
  'StaticPageLocalesDropdown',
  () => import('components_shared/molecules/StaticPageLocalesDropdown'),
)
registerComponent(
  'ContributorNavbarWrapper',
  () => import('components_contributor/ContributorNavbarWrapper'),
)
